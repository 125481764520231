<template>
    <AppCard
        :id="getId('load-*-billing-details', [load])"
        title="Billing Details"
        theme="blue"
        content-class="p-4">
        <LoadCustomerBillingNumbers :load="load" />
        <hr class="my-4 border-iel-gray" />

        <LoadCustomerRates :load="load" />
        <hr class="my-4 border-iel-gray" />

        <AppTextarea
            :id="getId('load-*-billing-notes', [load])"
            v-model="load.billing_notes"
            permission="update_load_billing_details"
            name="billing_notes"
            label="Internal Billing Notes"
            input-class="resize-none"
            rows="6"
            :url="url"
            :disabled="loadDisabled" />
    </AppCard>
</template>

<script setup>
import { useLoadDisabled } from '@/helpers';
import { getId } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const url = route('loads.update', props.load);

const loadDisabled = useLoadDisabled(props.load);
</script>
