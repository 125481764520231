<template>
    <div class="relative w-full max-w-full">
        <table
            class="relative w-full min-w-max border-separate border-spacing-x-0 border-spacing-y-2 overflow-x-auto"
            :class="classes?.table">
            <thead
                class="z-10 text-sm text-iel-dark-gray"
                :class="{ 'sticky top-0 bg-iel-silver': stickyHeader }">
                <tr>
                    <th
                        v-for="column in columns"
                        :key="column.name"
                        class="p-2 text-left first:pl-4 last:pr-4">
                        <button
                            v-if="column.sortable"
                            type="button"
                            class="group inline-flex"
                            @click="sort(column.name)">
                            {{ column.label }}
                            <span
                                class="ml-2 flex-none rounded bg-white text-iel-darkest-gray group-hover:bg-iel-dark-gray group-focus:bg-iel-dark-gray"
                                :class="{ 'invisible group-focus:visible': !isActive(column.name) }">
                                <svg
                                    class="h-5 w-5"
                                    :class="{
                                        'invisible flex-none rounded text-iel-gray group-hover:visible group-focus:visible':
                                            !isActive(column.name)
                                    }"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                        v-show="showArrow(column.name, 'desc')"
                                        fill-rule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                        clip-rule="evenodd"></path>
                                    <path
                                        v-show="showArrow(column.name, 'asc')"
                                        fill-rule="evenodd"
                                        d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                                        clip-rule="evenodd" />
                                </svg>
                            </span>
                        </button>
                        <span v-else>{{ column.label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!data.length">
                    <td
                        :colspan="columns.length"
                        class="rounded-lg bg-white/75 px-4 py-2 italic text-iel-gray">
                        {{ $t('No data') }}
                    </td>
                </tr>
                <tr
                    v-for="row in data"
                    v-else
                    :key="row.id"
                    class="rounded-lg shadow"
                    :class="classes?.row"
                    @click="$emit('row-click', row)">
                    <td
                        v-for="column in columns"
                        :id="`row-${row.id}-${column.name}-${row[column.name]}`"
                        :key="column.name"
                        class="bg-white p-2 text-sm first:rounded-l-lg first:pl-4 last:rounded-r-lg last:pr-4 md:text-base">
                        <slot
                            :name="`${column.name}-cell`"
                            :row="row">
                            {{ row[column.name] }}
                        </slot>
                    </td>
                    <td
                        v-if="$slots.actions"
                        :id="`row-${row.id}-actions`"
                        class="bg-white p-2 text-sm first:rounded-l-lg first:pl-4 last:rounded-r-lg last:pr-4 md:text-base">
                        <slot
                            name="actions"
                            v-bind="{ ...row }" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const props = defineProps({
    columns: {
        type: Array,
        required: true
    },
    data: {
        type: Array,
        required: true
    },
    dataKey: {
        type: String,
        required: false
    },
    classes: {
        type: Object,
        default: () => ({
            row: '',
            table: ''
        })
    },
    stickyHeader: {
        type: Boolean,
        default: false
    }
});

defineEmits(['row-click']);

const params = new URLSearchParams(location.search);

function isActive(column) {
    return params.get('orderBy') === column;
}

function showArrow(column, direction) {
    if (isActive(column)) {
        return params.get('orderByDir') === direction;
    } else {
        return direction === 'desc';
    }
}

function sort(column) {
    if (isActive(column) && params.get('orderByDir') === 'asc') {
        params.delete('orderBy');
        params.delete('orderByDir');
    } else if (isActive(column)) {
        params.set('orderByDir', 'asc');
    } else {
        params.set('orderBy', column);
        params.set('orderByDir', 'desc');
    }

    const url = `${window.location.pathname}?${params.toString()}`;

    if (!props.dataKey) {
        window.location = url;
    }

    router.visit(url, { only: [props.dataKey] });
}
</script>
