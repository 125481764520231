<template>
    <AppCard
        :id="getId('load-*-details', [load])"
        title="Load Details"
        theme="blue"
        content-class="p-4">
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <div class="col-span-2">
                <AppSelect
                    :id="getId('load-*-transportation-mode', [load])"
                    name="transportation_mode"
                    :model-value="load.transportation_mode"
                    permission="update_load_details"
                    :url="url"
                    :options="transportationModes"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />

                <AppErrorMessage
                    :id="getId('load-*-transportation-mode-warning', [load])"
                    name="all_risk_required_ltl"
                    :error="allRiskRequiredLTL" />
            </div>

            <div class="col-span-2">
                <AppSelect
                    :id="getId('load-*-value-range', [load])"
                    name="value_range"
                    label="Load Value"
                    :model-value="load.value_range"
                    permission="update_load_details"
                    :url="url"
                    :options="valueRanges"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />

                <AppErrorMessage
                    :id="getId('load-*-value-range-warning', [load])"
                    name="all_risk_required_value_range"
                    :error="allRiskRequiredValueRange" />
            </div>

            <AppSelect
                :id="getId('load-*-commodity-type', [load])"
                name="commodity_type"
                :model-value="load.commodity_type"
                permission="update_load_details"
                :url="url"
                :options="commodityTypes"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="col-span-2" />

            <AppSelect
                :id="getId('load-*-commodity-value', [load])"
                name="commodity_value"
                :model-value="load.commodity_value"
                permission="update_load_details"
                :url="url"
                :options="commodityValues"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="col-span-2" />

            <AppSelect
                :id="getId('load-*-required-equipment-type', [load])"
                name="required_equipment_type"
                :model-value="load.required_equipment_type"
                permission="update_load_details"
                :url="url"
                :confirmation="confirmation"
                :disabled="loadDisabled"
                :options="equipmentTypes"
                class="col-span-2" />

            <div class="col-span-2 grid grid-cols-3 items-start gap-0.5">
                <AppInput
                    :id="getId('load-*-temperature-value', [load])"
                    v-model="temperatureValue"
                    permission="update_load_details"
                    name="temperature"
                    :url="url"
                    class="col-span-2"
                    :confirmation="confirmation"
                    :disabled="tempDisabled" />

                <AppSelect
                    :id="getId('load-*-temperature-units', [load])"
                    name="temperature_units"
                    label=""
                    class="relative -bottom-6"
                    :model-value="temperatureUnitValue"
                    permission="update_load_details"
                    :url="url"
                    :options="{ c: 'C', f: 'F' }"
                    :confirmation="confirmation"
                    :disabled="tempDisabled" />
            </div>

            <AppDisplayField
                :id="getId('load-*-trip-miles', [load])"
                label="Trip Miles"
                :value="$page.props.load.trip_miles?.toLocaleString()"
                :error="tripMilesError"
                class="col-span-1" />

            <AppInput
                :id="getId('load-*-customer-miles', [load])"
                v-model="load.customer_miles"
                permission="update_load_details"
                name="customer_miles"
                :disabled="loadDisabled"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('load-*-cequipment length', [load])"
                v-model="load.equipment_length"
                permission="update_load_details"
                name="equipment_length"
                label="Equip. Length"
                :url="url"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="col-span-1" />

            <div class="col-span-2 flex items-end justify-center">
                <AppDisplayField
                    :id="getId('load-*-weight-value', [load])"
                    label="Total Weight"
                    :value="$page.props.load.total_load_weight" />

                <AppDisplayField
                    :id="getId('load-*-weight-units', [load])"
                    label=""
                    :value="$page.props.load.total_load_weight_description" />
            </div>

            <AppInput
                :id="getId('load-*-total-pallets', [load])"
                v-model="load.total_pallets"
                permission="update_load_details"
                name="total_pallets"
                :disabled="loadDisabled"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('load-*-total-pallets', [load])"
                v-model="load.total_pieces"
                permission="update_load_details"
                name="total_pieces"
                :disabled="loadDisabled"
                :url="url"
                class="col-span-2" />
        </div>

        <hr class="my-4 border-iel-gray" />

        <div class="grid grid-cols-2 gap-2">
            <div
                :id="getId('load-*-details-references', [load])"
                class="w-full">
                <AppHeading
                    :id="getId('load-*-references-header', [load])"
                    size="lg">
                    References
                </AppHeading>

                <table
                    v-if="form.references.length"
                    :id="getId('load-*-details-references-table', [load])"
                    class="NX-mobile-stacked w-full">
                    <thead>
                        <tr>
                            <th>{{ $t('Type') }}</th>
                            <th>{{ $t('Number') }}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(reference, index) in form.references"
                            :key="index">
                            <td>
                                <div class="flex items-center gap-2">
                                    <AppSelect
                                        :id="getId('load-*-reference-*-description', [load, { id: index }])"
                                        v-model="reference.description"
                                        permission="update_load_billing_details"
                                        :name="`load-${load.id}-reference-description-${index}`"
                                        label=""
                                        class="w-full"
                                        :disabled="loadDisabled"
                                        :options="{
                                            BOL: 'BOL',
                                            'Delivery Number': 'Delivery Number',
                                            PRO: 'PRO',
                                            SAP: 'SAP',
                                            'Customer Reference': 'Customer Reference',
                                            'Freight Agreement Number': 'Freight Agreement Number',
                                            'Purchase Order Number': 'Purchase Order Number',
                                            'Release Number': 'Release Number',
                                            Other: 'Other'
                                        }" />

                                    <AppInput
                                        v-if="reference.description === 'Other'"
                                        :id="getId('load-*-reference-*-other-description', [load, { id: index }])"
                                        v-model="reference.other"
                                        permission="update_load_billing_details"
                                        :name="`load-${load.id}-other-reference-description-${index}`"
                                        placeholder="Type"
                                        :disabled="loadDisabled" />
                                </div>
                            </td>

                            <td>
                                <AppInput
                                    :id="getId('load-*-reference-*-number', [load, { id: index }])"
                                    v-model="reference.number"
                                    permission="update_load_billing_details"
                                    :name="`load-${load.id}-reference-number-${index}`"
                                    label=""
                                    placeholder="Number"
                                    :disabled="loadDisabled" />
                            </td>

                            <td>
                                <AppButton
                                    :id="getId('load-*-reference-*-remove-button', [load, { id: index }])"
                                    variant="outline"
                                    icon="far fa-trash-can"
                                    type="button"
                                    permission="update_load_billing_details"
                                    :disabled="loadDisabled"
                                    @click="removeReference(index)" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p
                    v-else
                    class="text-sm">
                    This load has no references.
                </p>

                <AppButton
                    :id="getId('load-*-reference-add-button', [load])"
                    permission="update_load_billing_details"
                    icon="far fa-circle-plus"
                    class="mt-2"
                    @click="debouncedAddReference">
                    {{ $t('Add Reference') }}
                </AppButton>
            </div>

            <AppTextarea
                :id="getId('load-*-general-notes', [load])"
                v-model="load.general_notes"
                permission="update_load_details"
                name="general_notes"
                label="Internal General Notes"
                input-class="resize-none"
                rows="8"
                :url="generalNotesUrl"
                :disabled="loadDisabled" />
        </div>
    </AppCard>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { values, zipObject } from 'lodash-es';
import { useLoadDisabled } from '@/helpers';
import { watchDebounced } from '@vueuse/core';
import { debounce } from 'lodash-es';
import { getId } from '@/helpers';

defineProps({
    stop: Object
});

const page = usePage();
const load = computed(() => page.props.load);

const equipmentTypes = computed(() =>
    zipObject(values(page.props.options.equipment_types), values(page.props.options.equipment_types))
);

const valueRanges = computed(() =>
    zipObject(values(page.props.options.value_ranges), values(page.props.options.value_ranges))
);

const transportationModes = computed(() =>
    zipObject(values(page.props.options.transportation_modes), values(page.props.options.transportation_modes))
);

const commodityTypes = computed(() => page.props.options.commodity_types);
const commodityValues = computed(() =>
    zipObject(values(page.props.options.commodity_values), values(page.props.options.commodity_values))
);

const temperatureValue = computed(() =>
    page.props.load.commodity_type === 'dry' ? null : page.props.load.temperature
);

const temperatureUnitValue = computed(() =>
    page.props.load.commodity_type === 'dry' ? null : page.props.load.temperature_units
);

const url = route('loads.update', page.props.load);
const generalNotesUrl = route('loads.general-notes.update', page.props.load);

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

const tripMilesError = computed(() => {
    if (page.props.load.trip_miles || page.props.load.stops.length < 2) return null;
    return 'We are unable to calculate because one of the stops is invalid.';
});

const form = useForm({
    references: page.props.load.references || []
});

provide('form', form);

watchDebounced(
    form.references,
    () => {
        form.patch(route('loads.references.update', page.props.load), {
            preserveScroll: true
        });
    },
    { debounce: 1000 }
);

function removeReference(index) {
    form.references.splice(index, 1);
}

function addReference() {
    form.references.push({
        number: null,
        description: null,
        other: null
    });
}

const debouncedAddReference = debounce(addReference, 1000);

const allRiskRequiredLTL = computed(() => {
    if (page.props.load.transportation_mode === 'LTL' && !page.props.load.all_risk) {
        return 'Please create an all risk insurance request for this load.';
    }
    return null;
});

const allRiskRequiredValueRange = computed(() => {
    if (page.props.load.value_range === '$100001+' && !page.props.load.all_risk) {
        return 'Please create an all risk insurance request for this load.';
    }
    return null;
});

const loadDisabled = useLoadDisabled(page.props.load);

const tempDisabled = computed(() => {
    if (page.props.load.is_tonu || page.props.load.is_canceled || page.props.load.commodity_type === 'dry') {
        return true;
    } else {
        return false;
    }
});
</script>
