<template>
    <div class="tabs flex gap-2">
        <div
            v-for="tab in tabs"
            :key="tab"
            class="tab cursor-pointer rounded rounded-b-none px-2 py-1 text-white"
            :class="activeTab === tab ? 'bg-iel-dark-gray' : 'bg-iel-gray/90'"
            @click="setActiveTab(tab)">
            {{ tab }}
        </div>
    </div>
    <div class="grid gap-2 bg-white p-4">
        <LoadStopDetails
            v-if="activeTab === 'Details'"
            :stop="stop" />
        <LoadStopSchedule
            v-else-if="activeTab === 'Schedule'"
            :stop="stop" />
        <LoadStopLineItems
            v-else-if="activeTab === 'Line Items'"
            :stop="stop" />
        <LoadStopActivity
            v-else-if="activeTab === 'Status'"
            :stop="stop"
            :load="load" />
    </div>
</template>

<script setup>
const props = defineProps({
    stop: Object,
    load: Object
});

const step = useLoadStepsStore(props.load.id).step;

const activeTab = ref('Details');

const tabs = ['Details', 'Schedule', 'Line Items', 'Status'];

function setActiveTab(tab) {
    activeTab.value = tab;
}

onMounted(() => {
    if (step.value.task === 'setup_appointments') setActiveTab('Schedule');
    if (step.value.phase === 'ship') setActiveTab('Status');
});
</script>
