<template>
    <div>
        <AppHeading size="lg">
            {{ $t('Pay Truck Lines') }}
        </AppHeading>

        <table
            v-if="load.customerLineItems.length"
            :id="getId('load-*-customer-billing-rates-table', [load])"
            class="NX-mobile-stacked w-full">
            <thead>
                <tr>
                    <th>{{ $t('Location') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Total') }}</th>
                    <th>{{ $t('Note') }}</th>
                </tr>
            </thead>

            <tbody>
                <LoadBillingLineItem
                    v-for="rate in load.customerLineItems"
                    :key="rate.id"
                    permission="update_load_billing_details"
                    :load-id="load.id"
                    :line-item="rate" />

                <tr class="font-bold">
                    <td colspan="8">
                        <AppHeading
                            size="md"
                            class="mr-4 mt-4 flex justify-end">
                            {{ $t('Total') }} $ {{ load.total_customer_billing_amount }}
                        </AppHeading>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no pay truck line items.') }}
        </div>

        <div class="flex items-center justify-between gap-2">
            <AppButton
                id="load-add-customer-rate-button"
                type="button"
                icon="far fa-circle-plus"
                permission="update_load_billing_details"
                :disable="loadDisabled || Boolean(load?.billingDetail?.invoiced_at)"
                @click="debouncedAddCustomerRate">
                {{ $t('Add Pay Truck Line') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';
import { getId } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addCustomerRate() {
    router.post(
        route('loads.billing.store', props.load.id),
        { quantity: 1, amount: 0, line_type: 'Flat Rate', bill_type: 'customer' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddCustomerRate = debounce(addCustomerRate, 300);

const loadDisabled = useLoadDisabled(props.load);
</script>
