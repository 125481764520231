<template>
    <AppDropdown align="top-right">
        <template #trigger>
            <button
                :id="`customer-${customer.id}-actions-trigger`"
                class="flex"
                type="button"
                aria-label="dropdown menu to select load action options">
                <AppIcon
                    name="far fa-ellipsis"
                    class="cursor-pointer text-iel-blue" />
            </button>
        </template>
        <template #content>
            <button
                id="request-credit-button"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                        !props.customer.is_active || isOpenRequest('Credit Increase')
                }"
                type="button"
                :disabled="!props.customer.is_active || isOpenRequest('Credit Increase')"
                @click="openCustomerCreditRequestModal(customer)">
                {{ $t('Request Credit') }}
                <span
                    v-if="isOpenRequest('Credit Increase')"
                    class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('An open request for this type already exists.') }}
                </span>
                <span
                    v-if="!props.customer.is_active"
                    class="invisible absolute right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('Customer is inactive.') }}
                </span>
            </button>
            <button
                id="activate-customer-button"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                        props.customer.is_active || isOpenRequest('Customer Activation')
                }"
                type="button"
                :disabled="props.customer.is_active || isOpenRequest('Customer Activation')"
                @click="openCustomerActivationModal(customer)">
                {{ $t('Activate') }}
                <span
                    v-if="props.customer.is_active"
                    class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('Customer is already active.') }}
                </span>
                <span
                    v-if="isOpenRequest('Customer Activation')"
                    class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('An open request for this type already exists.') }}
                </span>
            </button>
            <button
                id="customer-insurance-button"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                        isOpenRequest('Customer Insurance')
                }"
                type="button"
                :disabled="isOpenRequest('Customer Insurance')"
                @click="openCustomerInsuranceRequestModal(customer)">
                {{ $t('Customer Insurance') }}
                <span
                    v-if="isOpenRequest('Customer Insurance')"
                    class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('An open request for this type already exists.') }}
                </span>
            </button>
            <button
                id="customer-contract-button"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                        isOpenRequest('Customer Contract')
                }"
                type="button"
                :disabled="isOpenRequest('Customer Contract')"
                @click="openCustomerContractRequestModal(customer)">
                {{ $t('Customer Contract') }}
                <span
                    v-if="isOpenRequest('Customer Contract')"
                    class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                    {{ $t('An open request for this type already exists.') }}
                </span>
            </button>
        </template>
    </AppDropdown>
</template>

<script setup>
import axios from 'axios';

const props = defineProps({
    customer: Object
});

const { open: CustomerActivationModal } = useModal('CustomerActivationModal');
const { open: CustomerCreditRequestModal } = useModal('CustomerCreditRequestModal');
const { open: CustomerInsuranceRequestModal } = useModal('CustomerInsuranceRequestModal');
const { open: CustomerContractRequestModal } = useModal('CustomerContractRequestModal');

const openCustomerActivationModal = row => {
    CustomerActivationModal({
        modalId: 'customer-activation-modal',
        title: 'Request Customer Activation',
        ticketType: 'Activation',
        customer: row
    });
};

const openCustomerCreditRequestModal = row => {
    CustomerCreditRequestModal({
        modalId: 'customer-request-credit-modal',
        title: 'Request Credit Increase',
        ticketType: 'Credit Increase',
        customer: row
    });
};

const openCustomerInsuranceRequestModal = async row => {
    try {
        const response = await axios.get(`/customers/${row.id}/address`);
        const address = response.data;

        CustomerInsuranceRequestModal({
            modalId: 'customer-insurance-request-modal',
            title: 'Customer Insurance Request',
            ticketType: 'Insurance Request',
            customer: row,
            address: address.address
        });
    } catch (error) {
        console.error('Failed to fetch customer address:', error);
    }
};

const openCustomerContractRequestModal = async row => {
    try {
        const response = await axios.get(`/customers/${row.id}/address`);
        const address = response.data;

        CustomerContractRequestModal({
            modalId: 'customer-contract-request-modal',
            title: 'Customer Contract Request',
            ticketType: 'Customer Contract',
            customer: row,
            address: address.address
        });
    } catch (error) {
        console.error('Failed to fetch customer address:', error);
    }
};

const isOpenRequest = type => props.customer.open_tickets.includes(type);
</script>
