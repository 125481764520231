<template>
    <Head :title="customer.name" />
    <AppHeader
        title="Customers"
        :icon-component="IconCustomers" />
    <AppPanels
        id="customer-show-toggle"
        :title="customer.name"
        :sections="{
            credit: 'Credit',
            info: 'Information',
            billing: 'Billing',
            contacts: 'Contacts',
            ratecon: 'RateCon',
            documents: 'Documents',
            external: 'External',
            payHistory: 'Pay History',
            loadHistory: 'Load History'
        }">
        <template #meta>
            <a
                v-if="page.props.customer.customer_group"
                :href="route('customers.groups.show', page.props.customer.customer_group.id)"
                class="mx-2 whitespace-nowrap rounded-full bg-iel-dark-red px-1.5 py-1 text-xs font-semibold text-white"
                target="_blank">
                {{ page.props.customer.customer_group?.name }}
            </a>
        </template>
        <template #nav>
            <form
                id="toggle-active-inactive-form"
                @submit.prevent="submit">
                <AppToggle
                    v-model="form.is_active"
                    permission="toggle_customer"
                    class="font-bold uppercase text-white"
                    :label="['Active', 'Inactive']"
                    @click="toggleIsActive"
                    @change="submit" />
            </form>
        </template>

        <template #credit>
            <CustomerCreditCard />
        </template>
        <template #info>
            <CustomerInformationCard />
        </template>
        <template #billing>
            <CustomerBillingCard />
        </template>
        <template #contacts>
            <CustomerContactsCard />
        </template>
        <template #ratecon>
            <CustomerRateConCard />
        </template>
        <template #documents>
            <CustomerDocsCard
                :customer="customer"
                :document-types="documentTypes" />
        </template>
        <template #external>
            <CustomerExternalCard />
        </template>
        <template #payHistory>
            <CustomerPayHistoryCard />
        </template>
        <template #loadHistory>
            <CustomerLoadHistoryCard />
        </template>
    </AppPanels>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import IconCustomers from '/resources/img/icon-customers.svg';
import { values, zipObject } from 'lodash-es';

defineProps({
    customer: Object
});

const page = usePage();

const form = useForm({
    is_active: page.props.customer.is_active
});

provide('form', form);

function toggleIsActive(event) {
    if (!confirm("Are you sure you want to change this customer's status?")) event.preventDefault();
}

function submit() {
    form.patch(route('customers.status.update', page.props.customer.id));
}

const documentTypes = computed(() =>
    zipObject(values(page.props.options.documentTypes), values(page.props.options.documentTypes))
);
</script>
