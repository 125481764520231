<template>
    <div :id="getId('load-*-customer-billing-numbers', [load])">
        <AppHeading size="lg">
            {{ $t('Customer Billing Numbers') }}
        </AppHeading>

        <table
            v-if="form.customer_billing_numbers.length"
            :id="getId('load-*-customer-billing-numbers-table', [load])"
            class="NX-mobile-stacked w-1/2">
            <thead>
                <tr>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Number') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="(customerBilling, index) in form.customer_billing_numbers"
                    :key="index">
                    <td>
                        <div class="flex items-end gap-2">
                            <AppSelect
                                :id="getId('load-*-customer-billing-number-*-description', [load, { id: index }])"
                                v-model="customerBilling.description"
                                permission="update_load_billing_details"
                                :name="`load-${load.id}-customer-billing-${index}`"
                                label=""
                                class="w-full"
                                :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                                :error="form.errors[`customer_billing_numbers.${index}.description`]"
                                :options="{
                                    BOL: 'BOL',
                                    'Delivery Number': 'Delivery Number',
                                    PRO: 'PRO',
                                    SAP: 'SAP',
                                    'Customer Reference': 'Customer Reference',
                                    'Freight Agreement Number': 'Freight Agreement Number',
                                    'Purchase Order Number': 'Purchase Order Number',
                                    'Release Number': 'Release Number',
                                    Other: 'Other'
                                }" />

                            <AppInput
                                v-if="customerBilling.description === 'Other'"
                                :id="getId('load-*-customer-billing-number-*-description-other', [load, { id: index }])"
                                v-model="customerBilling.other"
                                permission="update_load_billing_details"
                                :name="`load-${load.id}-other-customer-billing-${index}`"
                                placeholder="Other"
                                class="w-5/6"
                                :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                                :error="form.errors[`customer_billing_numbers.${index}.other`]" />
                        </div>
                    </td>

                    <td>
                        <AppInput
                            :id="getId('load-*-customer-billing-number-*', [load, { id: index }])"
                            v-model="customerBilling.number"
                            permission="update_load_billing_details"
                            :name="`load-${load.id}-customer-billing-number-${index}`"
                            label=""
                            placeholder="Number"
                            :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                            :error="form.errors[`customer_billing_numbers.${index}.number`]"
                            class="mt-1" />
                    </td>

                    <td>
                        <AppButton
                            :id="getId('load-*-customer-billing-number-*-remove-button', [load, { id: index }])"
                            variant="outline"
                            icon="far fa-trash-can"
                            type="button"
                            permission="update_load_billing_details"
                            :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                            @click="removeCustomerBillingNumber(index)" />
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no billing numbers.') }}
        </div>

        <AppButton
            :id="getId('load-*-customer-billing-number-add-button', [load])"
            permission="update_load_billing_details"
            icon="far fa-circle-plus"
            class="mt-2"
            @click="debouncedAddCustomerBillingNumber">
            {{ $t('Add Billing Number') }}
        </AppButton>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { watchDebounced } from '@vueuse/core';
import { debounce } from 'lodash-es';
import { useLoadDisabled } from '@/helpers';
import { getId } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const form = useForm({
    customer_billing_numbers: props.load.customer_billing_numbers || []
});

watchDebounced(
    form.customer_billing_numbers,
    () => {
        form.patch(route('loads.customer-billing-numbers.update', props.load), {
            preserveScroll: true
        });
    },
    { debounce: 1000 }
);

function removeCustomerBillingNumber(index) {
    form.customer_billing_numbers.splice(index, 1);
}

function addCustomerBillingNumber() {
    form.customer_billing_numbers.push({
        description: null,
        other: null,
        number: null
    });
}

const debouncedAddCustomerBillingNumber = debounce(addCustomerBillingNumber, 1000);

const loadDisabled = useLoadDisabled(props.load);
</script>
