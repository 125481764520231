<template>
    <tr>
        <td>
            <AppSelect
                :id="getId('load-*-*-*-location', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.location_id"
                name="location_id"
                required
                placeholder="Location"
                :options="locations"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppSelect
                :id="getId('load-*-*-*-type', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.line_type"
                name="line_type"
                required
                placeholder="Type"
                :options="lineItem.bill_type !== 'accessorial' ? rateTypes : accessorialRatesOptions"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-quantity', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.quantity"
                name="quantity"
                placeholder="Quantity"
                type="number"
                :min="0"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-amount', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.amount"
                name="amount"
                placeholder="Amount"
                type="number"
                :min="0"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <div class="whitespace-nowrap text-sm">$ {{ lineItem.total }}</div>
        </td>

        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-bill', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.bill_to_customer"
                :name="'bill_to_customer'"
                label=""
                :stacked="true"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-pay-carrier', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.pay_carrier"
                :name="'pay_carrier'"
                label=""
                :stacked="true"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-receipt required', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.receipt_required"
                :name="'receipt_required'"
                label=""
                :stacked="true"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-note', idParams)"
                :scope="lineItem.id"
                :model-value="lineItem.note"
                name="note"
                placeholder="Note"
                type="text"
                :url="url"
                :disable="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <div class="flex items-center">
                <AppButton
                    :id="getId('load-*-*-*-remove-button', idParams)"
                    variant="outline"
                    icon="far fa-trash-can"
                    type="button"
                    :disable="isDisabled"
                    :title="unauthorized ? $t('Unauthorized') : ''"
                    class="ml-auto"
                    @click="removeRate(lineItem.id)" />
            </div>
        </td>
    </tr>
</template>

<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { fromPairs, zipObject, values } from 'lodash-es';
import { accessorialCharges } from '@/data.js';
import { useLoadDisabled } from '@/helpers';
import { getId } from '@/helpers';

const props = defineProps({
    loadId: {
        type: Number,
        required: true
    },
    lineItem: {
        type: Object,
        default: () => {}
    },
    permission: String
});

const page = usePage();
const load = computed(() => page.props.load);

const url = route('loads.billing.update', { load: props.loadId, lineItem: props.lineItem });

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

const rateTypes = zipObject(values(page.props.options.rate_types), values(page.props.options.rate_types));

const accessorialRatesOptions = zipObject(accessorialCharges, accessorialCharges);

const locations = computed(() => fromPairs(page.props.load.stops.map(stop => [stop.location.id, stop.location.name])));

function removeRate(lineItem) {
    router.delete(route('loads.billing.destroy', [props.loadId, lineItem]), { preserveScroll: true, only: ['load'] });
}

const loadDisabled = useLoadDisabled(page.props.load);

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

const isDisabled = computed(
    () => loadDisabled.value || unauthorized.value || Boolean(load.value.billingDetail?.invoiced_at)
);

const idParams = [{ id: props.loadId }, { id: props.lineItem.bill_type }, { id: props.lineItem.id }];
</script>
