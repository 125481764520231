<template>
    <div :id="getId('load-*-accessorials', [load])">
        <AppHeading
            :id="getId('load-*-accessorials-heading', [load])"
            size="lg">
            {{ $t('Accessorials') }}
        </AppHeading>

        <table
            v-if="load.accessorialLineItems.length"
            :id="getId('load-*-accessorials-table', [load])"
            class="NX-mobile-stacked w-full">
            <thead>
                <tr>
                    <th>{{ $t('Location') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Total') }}</th>
                    <th class="text-center text-xs">{{ $t('Bill to Customer') }}</th>
                    <th class="text-center text-xs">{{ $t('Pay Carrier') }}</th>
                    <th class="text-center text-xs">{{ $t('Receipt Required') }}</th>
                    <th>{{ $t('Note') }}</th>
                </tr>
            </thead>
            <tbody :id="getId('load-*-accessotial-line-items', [load])">
                <LoadBillingLineItem
                    v-for="rate in load.accessorialLineItems"
                    :key="rate.id"
                    permission="update_accessorial_charges"
                    :load-id="load.id"
                    :line-item="rate" />
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no accessorial charges.') }}
        </div>

        <AppButton
            :id="getId('load-*-add-accessotial-item-button', [load])"
            type="button"
            permission="update_accessorial_charges"
            icon="far fa-circle-plus"
            :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
            class="mt-2"
            @click="debouncedAddAccessorialRate">
            {{ $t('Add Accessorial') }}
        </AppButton>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';
import { getId } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addAccessorialRate() {
    router.post(
        route('loads.billing.store', props.load.id),
        { quantity: 1, amount: 0, bill_type: 'accessorial' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddAccessorialRate = debounce(addAccessorialRate, 1000);

const loadDisabled = useLoadDisabled(props.load);
</script>
