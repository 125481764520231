<template>
    <LoadHeader />

    <div class="m-3">
        <!-- Header -->
        <header
            :id="getId('load-*-header', [load])"
            class="flex items-center justify-between rounded-t bg-iel-blue px-4 py-3">
            <div
                :id="getId('load-*-details-header', [load])"
                class="flex items-center gap-8 text-sm text-white">
                <div class="flex items-center gap-3 font-bold">
                    <Link
                        :id="getId('load-*-back-button', [load])"
                        :href="route('loads.index')"
                        aria-label="Return to all loads">
                        <AppIcon
                            name="far fa-arrow-left"
                            class="text-white" />
                    </Link>
                    <span>#{{ load.po_number }}</span>
                    <Link
                        v-if="load.customer?.name"
                        :id="getId('load-*-customer-link', [load])"
                        :href="route('customers.show', load.customer.id)"
                        class="underline">
                        {{ load.customer.name }}
                    </Link>
                    <span>|</span>
                    <div
                        v-if="load.carrier"
                        class="group relative">
                        <Link
                            :id="getId('load-*-carrier-link', [load])"
                            :href="route('carriers.show', load.carrier.id)"
                            class="underline">
                            {{ load.carrier?.name }}
                        </Link>
                        <div
                            class="absolute left-0 z-50 hidden w-32 rounded-md border bg-white p-4 text-sm text-black shadow-md group-hover:block">
                            <p>DOT: {{ load.carrier?.dot_number }}</p>
                            <p>MC: {{ load.carrier?.mc_number }}</p>
                            <p>MX: {{ load.carrier?.mx_number }}</p>
                            <p>FF: {{ load.carrier?.ff_number }}</p>
                        </div>
                    </div>
                </div>
                <AppPhoneLink
                    v-if="load.dispatcher"
                    :id="getId('load-*-call-dispatcher', [load])"
                    :tooltip="true"
                    :number="load.dispatcher?.phone_number"
                    :name="load.dispatcher?.name"
                    title="Dispatcher" />
                <AppPhoneLink
                    v-if="load.driver"
                    :id="getId('load-*-call-driver', [load])"
                    :tooltip="true"
                    :number="load.driver?.phone_number"
                    :name="load.driver?.name"
                    :title="load.driver2 ? 'Driver 1' : 'Driver'" />
                <AppPhoneLink
                    v-if="load.driver2"
                    :id="getId('load-*-call-driver-2', [load])"
                    :tooltip="true"
                    :number="load.driver2?.phone_number"
                    :name="load.driver2?.name"
                    title="Driver 2" />
                <LoadStopTracker
                    v-if="load.stops.length > 0 && load.phase === 'ship'"
                    :id="getId('load-*-stop-tracker', [load])" />
            </div>
            <div
                :id="getId('load-*-actions-bar', [load])"
                class="flex items-center gap-4">
                <div
                    v-if="pastDueCount > 0 || warningCount > 0"
                    class="group relative">
                    <AppWarningIcon
                        size="md"
                        class="mr-1" />
                    <span
                        class="absolute inset-x-0 -bottom-8 z-50 hidden w-28 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
                        {{ alerts }}
                    </span>
                </div>
                <div
                    v-if="load.all_risk && !load.all_risk?.resolved_at"
                    class="group relative">
                    <AppIcon
                        name="fas fa-shield-exclamation"
                        class="text-white" />
                    <span
                        class="absolute inset-x-0 -bottom-8 z-50 hidden w-32 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
                        {{ $t('All Risk Pending') }}
                    </span>
                </div>
                <div
                    v-if="load.all_risk?.resolution == 'approved'"
                    class="group relative">
                    <AppIcon
                        name="fas fa-shield-check"
                        class="text-white" />
                    <span
                        class="absolute inset-x-0 -bottom-8 z-50 hidden w-32 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
                        {{ $t('All Risk Approved') }}
                    </span>
                </div>
                <div
                    v-if="load.all_risk?.resolution == 'denied'"
                    class="group relative">
                    <AppIcon
                        name="fas fa-shield-xmark"
                        class="text-white" />
                    <span
                        class="absolute inset-x-0 -bottom-8 z-50 hidden w-32 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
                        {{ $t('All Risk Denied') }}
                    </span>
                </div>
                <a
                    v-if="load.claim"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-gavel"
                        class="text-white" />
                </a>
                <a
                    v-if="load.carrier_incident"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-handshake"
                        class="text-white" />
                </a>
                <a
                    v-if="load.notes"
                    href="#load-notes-detail-section">
                    <AppIcon
                        name="far fa-notebook"
                        class="text-white" />
                </a>
                <a
                    v-if="load.documents"
                    href="#load-docs-detail-section">
                    <AppIcon
                        name="far fa-file-lines"
                        class="text-white" />
                </a>
                <p
                    v-if="load.is_tonu || load.is_canceled"
                    class="text-iel-light-gray"
                    v-text="load.is_tonu ? 'TONU' : load.is_canceled ? 'CANCELED' : ''"></p>
                <LoadCardActionMenu
                    :load="load"
                    :options="options" />
                <button
                    :id="getId('load-*-expand-header-button', [load])"
                    @click="isHeaderExpanded = !isHeaderExpanded">
                    <AppIcon
                        name="far fa-caret-up"
                        class="h-6 w-6 rotate-180 text-white" />
                </button>
            </div>
        </header>

        <!-- Expanded Header -->
        <div
            v-if="isHeaderExpanded"
            class="w-full bg-white">
            <LoadSubHeader :load="load" />
        </div>

        <!-- Phases -->
        <div class="border-x border-iel-mercury">
            <LoadPhases
                :load="load"
                :show-summary="true"
                :read-step-from-store="true" />
        </div>

        <!-- Main Content -->
        <div
            v-if="componentsList"
            class="flex flex-col gap-4 border-x border-b border-iel-mercury bg-white p-4">
            <!-- TODO: do not send phase as prop -->
            <Component
                :is="component"
                v-for="(component, index) in componentsList"
                :key="index + step.phase"
                v-bind="{ load }" />
        </div>
        <LoadLegacyPanels
            v-else
            :load="load" />
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Toast } from '@/Composables/useSwal.js';
import { getId } from '@/helpers';
import LoadStopsList from '@/Pages/Loads/Partials/LoadStopsList.vue';
import LoadDetails from '@/Pages/Loads/Partials/LoadDetails.vue';
import LoadBillingDetails from '@/Pages/Loads/Partials/LoadBillingDetails.vue';
import LoadAccessorialCharges from '@/Pages/Loads/Partials/LoadAccessorialCharges.vue';
import LoadDetailCommunication from '@/Pages/Loads/Partials/LoadDetailCommunication.vue';
import LoadCarrierDetails from '@/Pages/Loads/Partials/LoadCarrierDetails.vue';
import LoadCarrierPayDetails from '@/Pages/Loads/Partials/LoadCarrierPayDetails.vue';
import LoadCarrierRate from '@/Pages/Loads/Partials/LoadCarrierRate.vue';
import LoadOverview from '@/Pages/Loads/Partials/LoadOverview.vue';
import LoadRateCon from '@/Pages/Loads/Partials/LoadRateCon.vue';
import LoadSummary from '@/Pages/Loads/Partials/LoadSummary.vue';
import LoadShipSummary from '@/Pages/Loads/Partials/LoadShipSummary.vue';
import LoadShipStops from '@/Pages/Loads/Partials/LoadShipStops.vue';

const props = defineProps({
    load: Object,
    options: Object
});

const isHeaderExpanded = ref(false);

const step = useLoadStepsStore(props.load.id).step;

const sections = {
    summary: [LoadSummary],
    setup: [LoadDetails, LoadBillingDetails, LoadAccessorialCharges, LoadStopsList, LoadDetailCommunication],
    'setup.create_build': [
        LoadDetails,
        LoadBillingDetails,
        LoadAccessorialCharges,
        LoadStopsList,
        LoadDetailCommunication
    ],
    'setup.add_load_tender': [
        LoadDetailCommunication,
        LoadDetails,
        LoadBillingDetails,
        LoadAccessorialCharges,
        LoadStopsList
    ],
    plan: [LoadStopsList, LoadDetailCommunication],
    'plan.route': [LoadStopsList, LoadDetailCommunication],
    'plan.setup_appointments': [LoadStopsList, LoadDetailCommunication],
    'plan.determine_carrier_rate': [LoadOverview, LoadStopsList, LoadCarrierRate, LoadDetailCommunication],
    'plan.book_carrier': [
        LoadOverview,
        LoadCarrierDetails,
        LoadCarrierPayDetails,
        LoadStopsList,
        LoadDetailCommunication
    ],
    'plan.send_carrier_rate_con': [LoadRateCon],
    'plan.receive_carrier_rate_con': [LoadRateCon],
    ship: [LoadShipSummary, LoadShipStops, LoadDetailCommunication]
};

const componentsList = computed(() => {
    return sections[step.value.key] || null;
});

const warningCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(
        task =>
            task.due_at &&
            !task.is_complete &&
            task.warning_minutes !== 0 &&
            now >= new Date(new Date(task.due_at).getTime() - task.warning_minutes * 60000) &&
            now < new Date(task.due_at)
    ).length;
});

const pastDueCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(task => task.due_at && !task.is_complete && now >= new Date(task.due_at)).length;
});

const alerts = computed(() => {
    return pastDueCount.value + warningCount.value > 0
        ? `${(pastDueCount.value || 0) + (warningCount.value || 0)} task(s)`
        : '';
});

onMounted(() => {
    initEcho();
});

function initEcho() {
    window.Pusher = Pusher;

    const laravelEcho = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        wsHost: import.meta.env.VITE_PUSHER_CLIENT_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        forceTLS: false,
        encrypted: true,
        enabledTransports: ['ws', 'wss'],
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
    });

    laravelEcho
        .private(`loads.${props.load.id}`)
        .listen('LoadWasUpdated', () => {
            router.reload({
                preserveScroll: true,
                only: ['load']
            });
        })
        .listen('MacropointOrderEvent', payload => {
            Toast.fire({
                title: payload.macropointResponse.message,
                icon: payload.macropointResponse.result
            });
        });
}
</script>
