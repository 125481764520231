<template>
    <div
        v-if="tasks"
        class="small-scrollbar flex items-center gap-2 overflow-x-auto overflow-y-hidden whitespace-nowrap border-b border-iel-light-gray bg-white px-4 pt-2 text-sm">
        <div
            v-for="(task, index) in tasks"
            :key="task.id"
            class="-mb-px flex cursor-pointer items-center gap-2"
            @click="handleTabClick(task)">
            <div
                class="flex items-center gap-2 border-b-4"
                :class="[selectedTask === task.name ? 'border-iel-blue' : 'border-transparent']">
                <div class="flex h-5 w-5 items-center justify-center rounded-full border border-iel-blue">
                    <AppIcon
                        v-if="task.is_complete"
                        name="fa fa-circle-check"
                        class="text-iel-blue" />
                </div>
                <div class="py-2">
                    {{ task.title }}
                </div>
            </div>
            <div
                v-if="index < tasks.length - 1"
                class="w-12 border-t border-iel-light-gray" />
        </div>
    </div>
</template>

<script setup>
import { get, last } from 'lodash-es';

const props = defineProps({
    load: Object
});

const { tasksByPhase, handleTaskClick } = useTasks(toRef(props, 'load'));

const step = useLoadStepsStore(props.load.id).step;

const tasks = computed(() => {
    // The "Setup" phase has special tasks
    if (step.value.phase === 'ship') {
        // This task is added to the "Ship" phase and indicates
        // if all stops have been departed (in other words, done)
        const stopStatusTask = {
            id: Number.MAX_SAFE_INTEGER,
            phase: 'ship',
            name: 'stop_status',
            title: 'Stop Status',
            order: 2,
            is_complete: props.load.stops.every(stop => stop.departed_at),
            completed_at: get(last(props.load.stops), 'completed_at'),
            callback: () => null
        };

        return tasksByPhase.value[step.value.phase]
            .filter(task => !task.stop_id)
            .map(task => {
                return {
                    callback: task.type === 'manual' || task.name === 'dispatch' ? () => handleTaskClick(task) : null,
                    ...task
                };
            })
            .toSpliced(1, 0, stopStatusTask);
    }

    return tasksByPhase.value[step.value.phase];
});

const selectedTask = computed(() => {
    if (step.value.task) return step.value.task;
    return get(tasksByPhase.value[step.value.phase], '0.name');
});

function handleTabClick(task) {
    if (task.callback) return task.callback();
    return selectStep(task);
}

function selectStep(task) {
    const key = step.value.phase + '.' + task.name;
    localStorage.setItem(`load_${props.load.id}_step_${step.value.phase}`, key);
    useLoadStepsStore(props.load.id).setStep(key);
}
</script>
