<template>
    <AppTabs
        :id="getId('load-*-communications-panel', [load])"
        :light="true">
        <div class="rounded-b-lg border border-iel-mercury p-4">
            <AppTab
                :id="getId('load-*-notes', [load])"
                :title="$t('Load Notes')"
                :active="!isAddLoadTender">
                <LoadNotes :load="load" />
            </AppTab>
            <AppTab
                :id="getId('load-*-checkcalls', [load])"
                :title="$t('Checkcalls')">
                <LoadCheckcalls :load="load" />
            </AppTab>
            <AppTab :title="$t('Texts')">
                <LoadTextMessages
                    :id="getId('load-*-texts', [load])"
                    :load="load" />
            </AppTab>
            <AppTab :title="$t('Tracking')">
                <LoadTracking
                    :id="getId('load-*-tracking', [load])"
                    :trackings="load.trackings" />
            </AppTab>
            <AppTab
                :title="$t('Documents')"
                :active="isAddLoadTender">
                <LoadDocs
                    :id="getId('load-*-documents', [load])"
                    :load="load" />
            </AppTab>
        </div>
    </AppTabs>
</template>

<script setup>
import { getId } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const step = useLoadStepsStore(load.id).step;

const isAddLoadTender = computed(() => step.value.task === 'add_load_tender');
</script>
