<template>
    <section class="p-2">
        <h2 class="font-bold text-iel-blue">{{ $t('Line Items') }}</h2>
        <ul
            v-if="stop.line_items.length > 0"
            :id="`load-${page.props.load.id}-stop-${stop.id}-line-items`">
            <li
                v-for="item in stop.line_items"
                :id="`line-item-${item.id}`"
                :key="item.id"
                class="mb-4 grid items-end gap-2 pt-8 first-of-type:pt-0 lg:grid-cols-3 xl:grid-cols-12 xl:pt-0">
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-reference-number-${item.id}`"
                    v-model="item.shipment_reference_number"
                    permission="update_stops"
                    name="shipment_reference_number"
                    :scope="stop.id"
                    label="Shipment Ref#"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-po-${item.id}`"
                    v-model="item.shipment_po"
                    permission="update_stops"
                    name="shipment_po"
                    :scope="stop.id"
                    label="Shipment PO"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-weight-${item.id}`"
                    v-model="item.weight"
                    permission="update_stops"
                    name="weight"
                    :scope="stop.id"
                    label="Weight"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-weight-unit-${item.id}`"
                    name="weight_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.weight_unit"
                    :options="$page.props.units.weight"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-volume-${item.id}`"
                    v-model="item.volume"
                    permission="update_stops"
                    name="volume"
                    :scope="stop.id"
                    label="Volume"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-volume-unit-${item.id}`"
                    name="volume_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.volume_unit"
                    :options="$page.props.units.volume"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-pieces-${item.id}`"
                    v-model="item.pieces"
                    permission="update_stops"
                    name="pieces"
                    :scope="stop.id"
                    label="Pieces"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-piece-unit-${item.id}`"
                    name="pieces_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.pieces_unit"
                    :options="$page.props.units.pieces"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-description-${item.id}`"
                    v-model="item.description"
                    permission="update_stops"
                    name="description"
                    :scope="stop.id"
                    label="Commodity Description"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)"
                    class="col-span-2" />
                <div>
                    <AppButton
                        icon="far fa-trash-can"
                        variant="outline"
                        class="inline-block transition hover:bg-iel-light-gray"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        @click="deleteLineItem(item)">
                        {{ $t('Delete') }}
                    </AppButton>
                </div>
            </li>
        </ul>
        <div v-else>{{ $t('No line items for this stop') }}</div>
        <AppButton
            :id="`load-${load.id}-stop-${stop.id}-add-new-line-item-button`"
            icon="fal fa-pen"
            class="mt-2 transition hover:bg-white hover:text-iel-blue"
            permission="update_stops"
            :disabled="loadDisabled"
            @click="addLineItem">
            {{ $t('New Line Item') }}
        </AppButton>
    </section>
</template>

<script setup>
import { usePage, router, useForm } from '@inertiajs/vue3';

import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    stop: Object
});

const page = usePage();

const load = computed(() => page.props.load);

function deleteLineItem(lineItem) {
    if (confirm('Delete Line Item?')) {
        router.delete(route('stops.lineItems.destroy', lineItem.id), {
            preserveScroll: true
        });
    }
}

function addLineItem() {
    router.post(route('stops.lineItems.store', props.stop), null, {
        preserveScroll: true
    });
}

const form = useForm({
    references:
        props.stop.references?.length > 0 ? props.stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);

const loadDisabled = useLoadDisabled(page.props.load);
</script>
