<template>
    <div class="-mb-4 -mr-4 -mt-4 w-96 shrink-0 rounded-md bg-iel-blue p-4">
        <div class="flex h-full flex-col gap-8 rounded-md bg-white p-4">
            <div class="flex gap-4">
                <LoadFinancesBox
                    label="Profit"
                    :value="data.profit" />
                <LoadFinancesBox
                    label="Margin"
                    :value="data.margin" />
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex items-center justify-between">
                    <button
                        class="cursor-pointer text-xl font-bold underline"
                        @click="goToSetupCarrierPay">
                        Carrier Pay
                    </button>
                    <button
                        class="rounded-full border border-iel-gray p-2"
                        title="Send Carrier RateCon"
                        @click="goToPlanSendCarrierRateCon">
                        <AppIcon
                            name="far fa-paper-plane"
                            class="block size-5" />
                    </button>
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadFinancesBox
                        label="Pay"
                        :value="data.carrierPay" />
                    <LoadFinancesBox
                        label="Min. Pay Amount"
                        :value="data.carrierMinPay" />
                    <LoadFinancesBox
                        label="Max. Pay Amount"
                        :value="data.carrierMaxPay" />
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadFinancesBox
                        label="Accessorials"
                        :value="data.carrierAccessorials" />
                    <LoadFinancesBox
                        label="Advances"
                        :value="data.carrierAdvances" />
                    <LoadFinancesBox
                        label="3rd Party"
                        :value="data.carrierAdvancesThirdParty" />
                </div>
                <LoadFinancesBox
                    label="Total Carrier Pay"
                    :value="data.carrierTotalPay" />
            </div>

            <div class="flex flex-col gap-4">
                <div class="text-xl font-bold">Customer Billing</div>
                <div class="grid grid-cols-2 gap-4">
                    <LoadFinancesBox
                        label="Billing"
                        :value="data.customerBilling" />
                    <LoadFinancesBox
                        label="Accessorials"
                        :value="data.customerAccessorials" />
                </div>
                <LoadFinancesBox
                    label="Total Customer Billing"
                    :value="data.customerBillingTotal" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { mapValues } from 'lodash-es';
import { formatMoney } from '@/helpers';

const props = defineProps({
    load: Object
});

const loadStats = useLoadStats(props.load);

const data = computed(() =>
    mapValues(loadStats, (stat, key) => {
        return key === 'margin' ? stat.value.toFixed(2) + '%' : formatMoney(stat.value);
    })
);

function goToPlanSendCarrierRateCon() {
    useLoadStepsStore(props.load.id).setStep('plan.send_carrier_rate_con');
}

function goToSetupCarrierPay() {
    useLoadStepsStore(props.load.id).setStep('setup');
    setTimeout(() => {
        const element = document.getElementById('load-detail-carrier-pay-rates');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}
</script>
