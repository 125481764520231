<template>
    <ul
        v-if="load.notes?.length > 0"
        role="list"
        class="mb-2 grid max-h-96 gap-2 overflow-y-auto"
        tabindex="0">
        <li
            v-if="primaryNote"
            :id="`note-${primaryNote.id}`"
            :key="primaryNoteKey">
            <AppMessage
                :user="primaryNote.user_name"
                :title="primaryNote.title"
                :body="primaryNote.content"
                :created-at="primaryNote.created_at"
                :icon="primaryNote.icon"
                size="lg">
                <AppButton
                    variant="outline"
                    icon="fas fa-star"
                    @click="setPrimaryNote(primaryNote)" />
            </AppMessage>
        </li>

        <li
            v-for="note in secondaryNotes"
            :id="`note-${note.id}`"
            :key="note.id">
            <AppMessage
                :user="note.user_name"
                :title="note.title"
                :body="note.content"
                :created-at="note.created_at"
                :icon="note.icon"
                size="lg">
                <AppButton
                    variant="outline"
                    icon="far fa-star"
                    @click="setPrimaryNote(note)" />
            </AppMessage>
        </li>
    </ul>

    <div v-else>{{ $t('No notes found on this load.') }}</div>

    <AppButton
        id="load-create-note-button"
        icon="far fa-pen"
        class="mt-2"
        @click="openCreateNoteModal">
        {{ $t('Create Note') }}
    </AppButton>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import axios from 'axios';

const props = defineProps({
    load: Object
});

const initialNoteIds = ref([]);
const primaryNoteKey = ref(0);

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    props.load.notes.map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);

const primaryNote = computed(() => (notes.value.length ? notes.value.find(n => n.is_primary) || null : null));

const secondaryNotes = computed(() => (notes.value.length ? notes.value.filter(n => !n.is_primary) : null));

onMounted(() => (initialNoteIds.value = props.load.notes.map(({ id }) => id)));

function openCreateNoteModal() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: props.load },
        primarySelectable: true
    });
}

async function setPrimaryNote(note) {
    await axios
        .put(
            route('notes.update', note.id),
            {
                content: note.content,
                is_primary: !note.is_primary
            },
            {
                headers: {
                    'X-Inertia': false
                }
            }
        )
        .then(() => {
            refreshNotes();
        });
}

function refreshNotes() {
    router.reload({
        preserveScroll: true,
        onSuccess: () => {
            primaryNoteKey.value++;
        }
    });
}
</script>
