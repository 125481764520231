<template>
    <section class="flex flex-col gap-4 md:w-1/2">
        <div class="grid grid-cols-3 gap-x-4 gap-y-2">
            <LoadCheckboxButton
                v-for="task in tasks"
                :key="task.id"
                :checked="task.is_complete"
                :load="load"
                :task="task"
                permission="edit_load_tasks"
                @complete-task="handleTaskClick(task)">
                {{ task.title }}
            </LoadCheckboxButton>
            <div
                v-for="task in tasks"
                :key="task.id"
                class="text-sm text-iel-dark-gray">
                {{ task.completed_at }}
            </div>
        </div>
        <div class="flex gap-2">
            <AppButton
                icon="far fa-circle-plus"
                @click="createTask">
                Add a Task
            </AppButton>
            <AppButton
                icon="far fa-circle-plus"
                @click="createCheckcall">
                Add a Check Call
            </AppButton>
        </div>
        <div>
            <AppInput
                v-model="stop.internal_note"
                permission="update_stops"
                name="internal_note"
                :scope="stop.id"
                :url="url"
                label="Internal Stop Note" />
        </div>
    </section>
</template>

<script setup>
import { last, endsWith } from 'lodash-es';

const props = defineProps({
    stop: Object,
    load: Object
});

const url = route('stops.update', props.stop);

const { tasksByPhase, handleTaskClick } = useTasks(toRef(props, 'load'));

const tasks = computed(() => {
    return tasksByPhase.value['ship']
        .filter(task => {
            return task.stop_id === props.stop.id;
        })
        .map(task => {
            return {
                ...task,
                title:
                    endsWith(task.name, 'arrived') ||
                    endsWith(task.name, 'completed') ||
                    endsWith(task.name, 'departed')
                        ? last(task.title.split(' '))
                        : task.title
            };
        });
});

function createCheckcall() {
    useModal('LoadCheckcallModal').open({
        mode: 'create',
        load: props.load,
        stop: props.stop,
        defaults: { event: 'En Route' }
    });
}

function createTask() {
    useModal('LoadTaskModal').open({
        mode: 'create',
        load: props.load,
        stop: props.stop
    });
}
</script>
