<template>
    <div class="min-h-screen bg-iel-lightest-gray/80 p-8">
        <div class="mx-auto max-w-screen-md">
            <img
                src="/images/arrow-IEL.png"
                alt="Logo"
                class="mb-8 h-20" />

            <template v-if="success">
                <h1 class="text-2xl font-bold">Your updates have been received.</h1>

                <p>You will receive an email confirmation.</p>
            </template>

            <template v-else>
                <div class="overflow-hidden rounded-lg border border-iel-light-gray bg-white shadow">
                    <div class="grid gap-2 border-b border-iel-light-gray bg-white p-8 text-center text-sm">
                        <h1 class="text-lg font-semibold text-iel-darkest-gray underline underline-offset-4">
                            Comcheck Advance Terms
                        </h1>

                        <h2 class="mx-auto w-auto bg-iel-light-yellow px-1 font-semibold">
                            Please select your Comcheck preferences in the boxes below.
                        </h2>

                        <p>
                            Your selection will remain until IEL receives written, authorized notice stating otherwise.
                            If this section is NOT filled out, your terms will default to not accept advances. Please
                            check the accessorial policy with your broker and submit receipts with billing paperwork
                            when invoicing.
                        </p>
                    </div>

                    <div>
                        <form @submit.prevent="submit">
                            <div class="my-4 grid grid-cols-12 justify-items-center p-4">
                                <div class="col-span-6">
                                    <AppLabel
                                        :label="$t('Dispatcher')"
                                        text-classes="text-base font-semibold mb-2" />

                                    <AppRadioGroup
                                        v-model="form.has_dispatcher_accessorials"
                                        name="has_dispatcher_accessorials"
                                        label="Accessorial Charges"
                                        :options="[
                                            { value: 1, label: 'Yes' },
                                            { value: 0, label: 'No' }
                                        ]"
                                        :error="form.errors.has_dispatcher_accessorials"
                                        inline
                                        class="mb-6" />

                                    <AppRadioGroup
                                        v-model="form.has_dispatcher_fuel_advance"
                                        name="has_dispatcher_fuel_advance"
                                        label="Fuel Advances"
                                        :options="[
                                            { value: 1, label: 'Yes' },
                                            { value: 0, label: 'No' }
                                        ]"
                                        :error="form.errors.has_dispatcher_fuel_advance"
                                        inline />
                                </div>

                                <div class="col-span-6">
                                    <AppLabel
                                        :label="$t('Driver')"
                                        text-classes="text-base font-semibold mb-2" />

                                    <AppRadioGroup
                                        v-model="form.has_driver_accessorials"
                                        name="has_driver_accessorials"
                                        label="Accessorial Charges"
                                        :options="[
                                            { value: 1, label: 'Yes' },
                                            { value: 0, label: 'No' }
                                        ]"
                                        :error="form.errors.has_driver_accessorials"
                                        inline
                                        class="mb-6" />

                                    <AppRadioGroup
                                        v-model="form.has_driver_fuel_advance"
                                        name="has_driver_fuel_advance"
                                        label="Fuel Advances"
                                        :options="[
                                            { value: 1, label: 'Yes' },
                                            { value: 0, label: 'No' }
                                        ]"
                                        :error="form.errors.has_driver_fuel_advance"
                                        inline />
                                </div>
                            </div>

                            <div class="grid gap-2 p-8 text-center text-sm">
                                <h2 class="text-lg font-semibold text-iel-darkest-gray underline underline-offset-4">
                                    Advances/Comcheck Rules
                                </h2>

                                <p class="font-semibold">
                                    Comchecks are available ONLY after freight has been loaded. Please indicate Yes or
                                    No for each item above. Not indicating Comcheck preferences, will default to NO
                                    ALLOW Comchecks. The person receiving the Comcheck must know the IEL PO number
                                    indicated on the Rate Confirmation. Fuel Advances will be approved with proof of
                                    loading for 40% of the flat rate of the load, up to $1,500.00.
                                </p>

                                <p class="mx-auto w-auto bg-iel-light-yellow px-1 font-semibold">
                                    ALL COMCHECKS WILL BE SUBJECT TO A $35 FEE FOR FUEL ADVANCES. COMCHECKS FOR
                                    ACCESSORIAL CHARGES WILL NOT INCUR A FEE WITH RECEIPT SUBMITTED WITH ORIGINAL
                                    INVOICE.
                                </p>
                            </div>

                            <div class="grid grid-cols-12 gap-x-2 gap-y-3 p-4">
                                <AppInput
                                    id="name"
                                    :model-value="carrier.name"
                                    readonly
                                    name="name"
                                    type="text"
                                    label="Carrier Name"
                                    class="col-span-6" />

                                <AppInput
                                    id="representative"
                                    v-model="form.representative"
                                    required
                                    name="representative"
                                    type="text"
                                    label="Carrier representative"
                                    :error="form.errors.representative"
                                    class="col-span-6" />

                                <AppInput
                                    id="name"
                                    :model-value="carrier.mc_number"
                                    readonly
                                    name="mc_number"
                                    type="text"
                                    label="MC#"
                                    class="col-span-6 mb-10" />

                                <AppInput
                                    id="name"
                                    :model-value="date"
                                    readonly
                                    name="date"
                                    type="text"
                                    label="Date"
                                    class="col-span-6 mb-10" />

                                <div class="col-span-12">
                                    <AppButton
                                        type="submit"
                                        class="ml-auto mt-4">
                                        Save Changes
                                    </AppButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </template>

            <div class="border-t border-iel-lightest-gray pt-6 text-center text-sm text-iel-dark-gray">
                Copyright © 2007-{{ new Date().getFullYear() }} Integrity Express Logistics
            </div>
        </div>
    </div>
</template>

<script setup>
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import { useForm } from '@inertiajs/vue3';

defineOptions({ layout: DefaultLayout });

const props = defineProps({
    carrier: Object,
    ticket: Object,
    date: String,
    ticket_id: Number
});

const form = useForm({
    ticket_id: props.ticket_id,
    representative: '',
    has_driver_fuel_advance: Number(props.carrier.has_driver_fuel_advance),
    has_driver_accessorials: Number(props.carrier.has_driver_accessorials),
    has_dispatcher_fuel_advance: Number(props.carrier.has_dispatcher_fuel_advance),
    has_dispatcher_accessorials: Number(props.carrier.has_dispatcher_accessorials)
});

const success = ref(false);

function submit() {
    form.post(route('carriers.advance-terms-change.store', props.carrier.id), {
        onSuccess() {
            success.value = true;
        }
    });
}
</script>
